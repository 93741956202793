import { MAX_ICONS_INLINE_SOURCES } from "@/constants";
import { SourceDocument } from "@/types/types";
import { getIconSrc, plural } from "@/utils/utils";
import { Button } from "../ui/button";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import { Sources } from "./Sources";
import { Skeleton } from "../ui/skeleton";
import FileText from "../../assets/FileText"
import Stack from "@/assets/Stack";

interface Props {
    documents: SourceDocument[];
    compact?: boolean;
    maxIcons?: number;
    loading?: boolean;
    className?: string;
}

interface WebIconsProps {
    documents: SourceDocument[];
    maxIcons?: number;
}

interface ViewSourcesProps {
    documents: SourceDocument[];
    compact?: boolean;
}

const iconStyle = "w-4 h-4 border rounded-[2px]";


function WebIcons({ documents, maxIcons }: WebIconsProps) {
    return (
        <div className="flex gap-1">
            {documents
                .filter(d => d.document_id.includes("web"))
                .reduce((acc, cur) => {
                    const { hostname } = new URL(cur.url);
                    if (acc.includes(hostname)) {
                        return acc;
                    }
                    return [...acc, hostname];
                }, [] as string[])
                .filter((_, idx) => idx < (maxIcons ? maxIcons : MAX_ICONS_INLINE_SOURCES))
                .map(hostname => {
                    const src = getIconSrc(hostname);
                    return (
                        <TooltipProvider key={src}>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <img src={src} className={iconStyle} />
                                </TooltipTrigger>
                                <TooltipContent className="mb-1">
                                    <span>{hostname}</span>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    )
                })}
        </div>
    )
}

function ViewSources({ documents, compact }: ViewSourcesProps) {
    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button variant={"tertiary"} className="flex gap-2">
                    {compact ? (
                        <Stack className="h-6 w-6 !stroke-link"/>
                    ) : (
                        <>
                           <Stack className="h-6 w-6"/>

                            <span className="font-body-strong text-system-primary">
                                View sources
                            </span>
                        </>
                    )}
                </Button>

            </DialogTrigger>
            <DialogContent className="sm:max-w-[527px] py-8 flex flex-col gap-10">
                <DialogHeader>
                    <DialogTitle>Sources</DialogTitle>
                    <DialogDescription>
                    </DialogDescription>
                </DialogHeader>
                <div className="grid grid-cols-1">
                    <Sources documents={documents} showTabs={true} />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export function PreviewSources({ documents, compact, maxIcons, loading, className }: Props) {
    if (loading) {
        return (
            <Skeleton className="w-full h-10 my-2" />
        )
    }

    if (documents.length === 0) return (
        <div className="w-full m-h-40px my-2">

        </div>
    )

    const webpages = documents.filter(d => d.document_id.includes("web"));
    const internalDocuments = documents.filter(d => !d.document_id.includes("web"));
    return (
        <div className={`$w-full ${className || ""}`}>
            <div className="flex flex-wrap gap-4 justify-between items-center">
                <div className="flex gap-6 items-center">
                    <div className="flex gap-2 items-center">
                        {webpages.length > 0 && (
                            <>
                                <div className="flex gap-1">
                                    <WebIcons documents={documents} maxIcons={maxIcons} />
                                </div>
                                <span className="font-label text-system-body flex md:!hidden !mb-0">{webpages.length}</span>
                                <span className="font-label text-system-body hidden md:!flex">{webpages.length} {plural("webpage", webpages.length)}</span>
                            </>
                        )}
                    </div>
                    <div className="flex gap-2 items-center">
                        {internalDocuments.length > 0 && (
                            <>
                                <FileText className="h-4 w-4 p-0.5 border border-system-border-light rounded-[2px]" />
                                <span className="font-label text-system-body flex md:!hidden !mb-0">{internalDocuments.length}</span>
                                <span className="font-label text-system-body hidden md:!flex">{internalDocuments.length} {plural("internal document", internalDocuments.length)}</span>
                            </>
                        )}
                    </div>
                </div>
                <ViewSources documents={documents} compact={compact} />
            </div>
        </div>
    )
}
