const Divider = ({ withDot }: { withDot?: boolean }) => {
    const dot = () => (
        <div className="h-1.5 w-1.5 bg-system-border-light rounded-full" />
    )
    return (
        <div className="flex gap-0 w-full items-center">
            {withDot && (
                dot()
            )}

            <div className="bg-system-border-light flex-grow h-[1px]"></div>

            {withDot && (
                dot()
            )}
        </div>
    )
}

export default Divider