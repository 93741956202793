import { useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Input } from "../ui/input";
import EditorJS, { OutputBlockData, OutputData } from '@editorjs/editorjs';
import { RootState, store } from "@/store/store";
import { checkUserFlag, getTimestamp } from "@/utils/utils";
import {
  setSelectedText,
  setToolComponent,
  setCursorTool,
  setToolCursor,
} from "./docGenSlice";
import { useDebounce } from "@/hooks/useDebounce";
import { Picker } from "./PanelTools/Picker";
import { FactCheckTool } from "./InlineTools/FactCheckTool";
import { ResearchTool } from "./InlineTools/ResearchTool";
import { ToolPanel } from "./ToolPanel";
import { FinalAnswerBlock } from "./BlockTools/FinalAnswer";
import { ASYNC_STATUS, Citation, EditReport, ResponseEntityExtraction, SourceDocument, SystemMessage } from "@/types/types";
import { PreviewSources } from "../Assistant/PreviewSources";
import { filterDocumentsByCited, getGlobalUniqueDocuments } from "@/utils/components";
import { LayoutContext } from "@/contexts/LayoutContext";
import { useWindowSize } from "@/hooks/useWindowSize";
import texture from '../../assets/bg-texture.png'
import { Button } from "../ui/button";
import { AskDesiaTool } from "./BlockTools/AskDesiaTool";
import { ParagraphTool } from "./BlockTools/ParagraphTool";
import { TypographyLabel } from "../ui/Typography";
import { CircleAlert, CircleCheckBigIcon, Loader2 } from "lucide-react";
import Checkmark from "@/assets/Checkmark";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { formatDistanceToNowStrict } from "date-fns";

function getAggregateSources(blocks: OutputBlockData[]) {
  let citations: Citation[] = [];
  let documents: SourceDocument[] = [];
  blocks.forEach(b => {
    if (b.type === 'FinalAnswer') {
      citations.push(...b.data.citations);
      documents.push(...b.data.documents);
    }
  })
  const citedDocuments = filterDocumentsByCited(documents, citations);
  const dedupedCitedDocuments = getGlobalUniqueDocuments(citedDocuments);
  return { citations, documents, citedDocuments: dedupedCitedDocuments };
}

function Editor({ data, instance, setInstance, cleanup, handleChange, onBlur, openToolbar }: {
  data: OutputData,
  instance: EditorJS | undefined,
  setInstance: (instance: EditorJS) => void, cleanup: () => void,
  handleChange: (data: OutputData) => void,
  onBlur: () => void
  openToolbar: () => void
}) {
  const dispatch = useDispatch();
  const initEditorCalled = useRef(false);
  const initEditor = () => {
    initEditorCalled.current = true;

    const editor = new EditorJS({
      holder: 'editorjs',
      onReady: () => {
        setInstance(editor);
        console.log("Editor -> instance", instance);

      },
      autofocus: true,
      data,
      onChange: async () => {
        let content = await editor.saver.save();
        console.info("onChange event content", content);
        handleChange(content);
      },
      inlineToolbar: ["FactCheck", "Research", "link", "bold", "italic"],
      defaultBlock: 'NewParagraph',
      tools: {
        AskDesia: {
          // @ts-expect-error
          class: AskDesiaTool,
          config: {
            onCreate: () => {
              dispatch(setCursorTool(null));
              dispatch(setToolComponent('ask'));
            }
          },
        },
        NewParagraph: {
          // @ts-expect-error
          class: ParagraphTool,
          inlineToolbar: true,
          config: {
            onSlash: () => openToolbar()
          }
        },
        FinalAnswer: {
          // @ts-expect-error
          class: FinalAnswerBlock,
          inlineToolbar: true,
        },
        FactCheck: {
          class: FactCheckTool,
          inlineToolbar: true,
          config: {
            handlers: {
              showPanel: () => {
                store.dispatch(setToolComponent('factCheck'));
              },
              setSelectedText: (text: string) => {
                store.dispatch(setSelectedText(text));
              }
            }
          }
        },
        Research: {
          class: ResearchTool,
          inlineToolbar: true,
          config: {
            handlers: {
              showPanel: () => {
                store.dispatch(setToolComponent('research'));

              },
              setSelectedText: (text: string) => {
                store.dispatch(setSelectedText(text));
              }
            }
          }
        },
      },
    });
  };


  useEffect(() => {
    if (!initEditorCalled.current) {
      initEditor();
    }

    return () => {
      cleanup();
    };

  }, []);

  return (
    <div id="editorjs" onBlur={() => onBlur()} className="py-10 h-[calc(100vh-150px)] text-system-body selection:bg-highlight-selected"></div>
  );
}

function checkUnsavedChanges(editReport: EditReport) {
  try {
    const { lastSave, server, client } = editReport;
    if (lastSave?.loading) return false;
    if (!server.data) return false;
    // title changed
    if (server.data.title !== client.title) return true;
    // report blocks changed
    const clientReportContent = JSON.stringify(client.report);
    // handle edge case of empty report - client needs to satisfy OutputData type
    if (server.data.content === "" && clientReportContent === '{"blocks":[]}') return false;
    if (server.data.content !== clientReportContent) return true;
    return false;
  } catch (e) {
    console.error(e);
    return false;
  }
}

function SaveControl({ editReport, handleSave, handleExtract }: {
  editReport: EditReport | null
  handleSave: () => void,
  handleExtract?: () => void,
}) {
  const [success, setSuccess] = useState(false)
  const error = editReport?.lastSave?.error;
  const loading = editReport?.lastSave?.loading;


  useEffect(() => {
    if (!loading) {
      setSuccess(!!editReport?.lastSave && !error && !loading)

      setTimeout(() => {
        setSuccess(false)
      }, 2000)
    }
  }, [loading])

  if (!editReport) return null;

  const changesPending = checkUnsavedChanges(editReport);

  if (!changesPending && !loading && !success) return null

  let buttonLabel = "";
  if (loading) {
    buttonLabel = "Saving";
  } else if (error) {
    buttonLabel = "Failed to save";
  } else if (success) {
    buttonLabel = "Saved";
  } else {
    buttonLabel = "Save";
  }

  const hasEntities = (editReport?.client?.extractedEntities?.entities || []).length > 0;
  const loadingEntities = editReport?.entityExtraction.status === ASYNC_STATUS.loading;
  const extractedEntities = editReport?.client?.extractedEntities;
  return (
    <div className="fixed right-4 top-4 z-[2] flex gap-2 items-center">
      {changesPending && (
        <TypographyLabel className="text-system-body">Unsaved changes</TypographyLabel>
      )}

      <Button variant={"secondary"} onClick={handleSave}>
        <div className="flex gap-2 items-center">
          {loading && (<Loader2 className="h-5 w-5 animate-spin flex-shrink-0" />)}
          {success && (<Checkmark className="h-6 w-6 shrink-0" />)}
          {buttonLabel}
        </div>
      </Button>

      {checkUserFlag("docgen: entity extraction") && (typeof handleExtract === "function") && (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button variant={hasEntities ? "secondary" : "primary"} disabled={loadingEntities} onClick={() => {
                handleExtract();
              }}>
                {hasEntities ? <CircleCheckBigIcon className="" /> : <CircleAlert className="" />}
                {loadingEntities ? "Extracting entities..." : "Extract entities"}
              </Button>
            </TooltipTrigger>
            <TooltipContent className="mb-1">
              {hasEntities && (
                <>
                <ul>
                  {extractedEntities && extractedEntities.entities.map(r => {
                    return <li className={r.isMainEntity ? "font-bold" : ""}>{JSON.stringify(r)}</li>
                  })}
                </ul>

                <div>Entities extracted {formatDistanceToNowStrict(new Date(extractedEntities!.extractionDateTime), { addSuffix: true })}</div>
                </>
              )}

              {!hasEntities && (
                <span>Click to generate entities for this report to help aid factCheck</span>
              )}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
    </div>
  )
}

export function ReportWriter({
  reportId,
  title,
  report,
  extractedEntities,
  handleTitleChange,
  handleReportChange,
  handleSave,
  handleExtract,
}: {
  reportId: string,
  title: string,
  report: OutputData,
  extractedEntities?: ResponseEntityExtraction,
  handleTitleChange: (title: string) => void,
  handleReportChange: (report: OutputData) => void,
  handleSave: () => void,
  handleExtract?: () => void,
}) {
  const dispatch = useDispatch();
  const editorInstance = useRef<EditorJS>();
  const layoutContext = useContext(LayoutContext)
  const size = useWindowSize()

  const panelTool = useSelector((state: RootState) => state.docGen.panelTool);
  const cursorTool = useSelector((state: RootState) => state.docGen.cursorTool);
  const selectedText = useSelector((state: RootState) => state.docGen.selectedText);
  const editReport = useSelector((state: RootState) => state.docGen.editReportById[reportId]);

  const panelToolOpen = panelTool.componentType !== null;
  const cursorToolOpen = cursorTool.componentType !== null;

  const [timestampLastInteraction, setTimestampLastInteraction] = useState(getTimestamp());
  const [lastFocusedBlockIndex, setLastFocusedBlockIndex] = useState<number | undefined>(undefined)

  const debouncedValue = useDebounce(timestampLastInteraction, 1000);
  const { citedDocuments } = getAggregateSources(report.blocks);

  const toolPanelWidth = Math.min(size.width - 900 - 32 - (layoutContext.showSidebar ? 330 : 0), 434)

  function addAssistantResponse(systemMessage: SystemMessage) {
    const lastIndex = lastFocusedBlockIndex
    let index: number | undefined = undefined
    if (lastIndex) {
      if (report.blocks[lastIndex].data.text) {
        index = lastIndex + 1
      } else {
        index = lastIndex
      }
    }

    editorInstance.current?.blocks.insert("FinalAnswer", {
      text: systemMessage.data.text,
      citations: systemMessage.data.citations,
      documents: systemMessage.data.documents,
      onBackspace: () => deleteBlock()
    }, {}, index, true);
  }

  function addAssistantResponseInline(partialSystemMessage: { text: string, citations: Citation[], documents: SourceDocument[] }, replaceHighlight: boolean) {
    if (replaceHighlight) {
      const currentBlockIndex = editorInstance.current?.blocks.getCurrentBlockIndex()
      if (currentBlockIndex === undefined) { return }

      const currentBlock = editorInstance.current?.blocks.getBlockByIndex(currentBlockIndex)
      if (currentBlock === undefined) { return }

      const text = report.blocks[currentBlockIndex].data.text
      const splitText = text.split(selectedText)

      const prefixText = splitText[0]
      const postfixText = splitText[1]

      const citations = partialSystemMessage.citations.map((citation) => {
        const mutatedCitation: Citation = {
          text: citation.text,
          start: citation.start + prefixText.length,
          end: citation.end + prefixText.length,
          document_ids: citation.document_ids,
        }
        return mutatedCitation
      })

      editorInstance.current?.blocks.insert("FinalAnswer", {
        text: `${prefixText} ${partialSystemMessage.text} ${postfixText}`,
        citations: citations,
        documents: partialSystemMessage.documents,
        onBackspace: () => deleteBlock()
      }, {}, undefined, true, true);
    } else {
      editorInstance.current?.blocks.insert("FinalAnswer", {
        text: partialSystemMessage.text,
        citations: partialSystemMessage.citations,
        documents: partialSystemMessage.documents,
        onBackspace: () => deleteBlock()
      }, {}, undefined, true);
    }
  }

  function deleteBlock() {
    const index = editorInstance.current?.blocks.getCurrentBlockIndex()
    editorInstance.current?.blocks.delete(index)
  }

  function openToolbarHandler() {
    editorInstance.current?.toolbar.toggleToolbox(true)
  }

  useEffect(() => {
    const selectedText = window.getSelection()?.toString();
    if (!cursorToolOpen && !panelToolOpen && !selectedText) {
      dispatch(setCursorTool('ask'));
    }
  }, [debouncedValue])

  useEffect(() => {
    layoutContext.toggleShowSidebar(false)
  }, [])

  return (
    <div className="w-full h-[calc(100vh-120px)] overflow-visible mt-[-40px]">
      <div className={`flex justify-center ${panelToolOpen ? "w-full" : ""}`}>
        <div className={`shrink-0 flex flex-grow justify-center group transition-all ease-in-out duration-500 mx-auto`}>
          <div className={`shrink-0 flex-grow w-max bg-white/20 max-w-[900px] relative overflow-hidden`}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
              setTimestampLastInteraction(getTimestamp());
              if (cursorToolOpen) {
                dispatch(setCursorTool(null));
              }
              const activeSelection = window.getSelection()?.toString();
              if (activeSelection !== selectedText) {
                dispatch(setSelectedText(activeSelection || ""));
              }
              if (e.key === '/') {
                const currentBlock = editorInstance.current?.blocks.getBlockByIndex(editorInstance.current.blocks.getCurrentBlockIndex())
                currentBlock?.call('checkText')
              }
            }}
            onClick={() => {
              const activeSelection = window.getSelection()?.toString();
              if (activeSelection !== selectedText) {
                dispatch(setSelectedText(activeSelection || ""));
              }
            }}
            onMouseMove={(evt) => {
              setTimestampLastInteraction(getTimestamp());
              if (!panelToolOpen) {
                dispatch(setToolCursor({ x: evt.pageX, y: evt.pageY }));
              }
              if (cursorToolOpen) {
                dispatch(setCursorTool(null));
              }
            }}
          >
            <div className="relative mb-8">
              <Input
                style={{ backgroundImage: `url(${texture})` }}
                className="max-w-[900px] text-center border-none font-h4 focus-visible:ring-0 text-system-primary"
                value={title} onChange={(e) => {
                  handleTitleChange(e.target.value)
                }}
                placeholder="Untitled document"
              />
            </div>
            <div className="bg-system-secondary border border-system-border-light rounded-lg overflow-auto">
              <div className="sticky top-0 z-[2]">
                <PreviewSources
                  documents={citedDocuments}
                  loading={false}
                  compact={false}
                  className="pt-8 px-10 pb-4 bg-system-secondary rounded-t-lg"
                />
              </div>
              <div>
                <Editor
                  data={report}
                  instance={editorInstance.current}
                  setInstance={(instance: EditorJS) => {
                    editorInstance.current = instance
                  }}
                  cleanup={() => {
                    editorInstance?.current?.destroy();
                    editorInstance.current = undefined;
                  }}
                  handleChange={(data: OutputData) => {
                    handleReportChange(data);
                  }}
                  onBlur={() => {
                    setLastFocusedBlockIndex(editorInstance.current?.blocks.getCurrentBlockIndex())
                  }}
                  openToolbar={openToolbarHandler}
                />
              </div>
            </div>
          </div>

          <div style={{ width: panelToolOpen ? `${toolPanelWidth}px` : '0px' }} className="transition-width ease-in-out duration-500 z-[3]">
            {panelToolOpen && (
              <div style={{ width: panelToolOpen ? `${toolPanelWidth}px` : '0px' }} className={`z-10 shrink flex-grow`}>
                <ToolPanel
                  show={panelToolOpen}
                  hide={() => {
                    dispatch(setToolComponent(null));
                  }}
                  offset={panelTool.cursor.y}
                  component={<Picker
                    reportId={reportId}
                    data={report}
                    componentType={panelTool.componentType}
                    extractedEntities={extractedEntities}
                    addToDocument={addAssistantResponse}
                    addToDocumentInline={addAssistantResponseInline} />}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <SaveControl editReport={editReport} handleSave={handleSave} handleExtract={handleExtract} />
    </div>
  );
}
