import { UserSettings } from "@/types/types";
import { checkUserFlag } from "./utils";

export function getAskTools(s: UserSettings['settings']) {
    const toolsEnabled = checkUserFlag("assistant: tools");
    if (!toolsEnabled) {
        return {};
    }

    let tools = [
        {"name": "python_interpreter"},
        {"name": "calculator"}
    ];

    if (s.assistant.sources.some(s => s.id === "web-search")) {
        tools.push({"name": "web_search"});
    }
    if (s.assistant.sources.some(s => s.id === "internal-search")) {
        tools.push({"name": "internal_search"});
    }

    return {
        tools,
    }
}
