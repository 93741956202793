import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { ResponseDocument } from "@/types/types"
import { Skeleton } from "../ui/skeleton";
import { formatBytes, friendlyOrgName, getFileLinkV2, getLocale } from "@/utils/utils";
import { ErrorMessage } from "../ErrorMessage";
import { getFileIcon } from "@/utils/components";
import { Checkbox } from "../ui/checkbox";
import { TypographyBody } from "../ui/Typography";
import { Badge } from "../ui/badge";
import { useContext } from "react";
import { UserContext } from "@/contexts/UserContext";

function getDirectoryFromDocument(d: ResponseDocument, organizationName: string) {
    if (d.document_is_part_of_desia_library) {
        return "Desia library";
    }
    return organizationName;
}

function getCreatedAt(d: ResponseDocument) {
    const date = d.document_created_at || d.created_at_desia;
    if (!date) return null;
    const locale = getLocale();
    return new Date(date).toLocaleDateString(locale, {
        day: "numeric",
        month: "short",
        year: "numeric",

    });
}

export function ResourceTable({ documents, loading, error, askSelection, selected, onSelect }: { documents: ResponseDocument[], loading: boolean, error: boolean, askSelection?: boolean, selected?: string[], onSelect?: (value: string) => void }) {
    const userContext = useContext(UserContext)
    const areAllDocumentsReady = documents.every((d) => d.document_is_ready_to_use)

    const getBadge = (status: string) => {
        switch (status) {
            case "uploading": return <Badge variant="teal" className="whitespace-nowrap">Uploading</Badge>
            case "available":
            case "failed": return <Badge variant="red" className="whitespace-nowrap">Failed to process</Badge>
            case "normalizing":
            case "chunking":
            case "embedding":
            case "entity_recognition":
            default: return <Badge variant="purple" className="whitespace-nowrap">Processing</Badge>
        }
    }
    return (
        <div>
            {!loading && !error && (
                <Table className="max-w-[1280px]">
                    <TableHeader>
                        <TableRow>
                            {askSelection && (
                                <TableHead className="font-body-strong text-system-primary"></TableHead>
                            )}
                            <TableHead className="font-body-strong text-system-primary !max-w-[640px] !overflow-hidden">File name</TableHead>
                            {askSelection ?
                                <>
                                    <TableHead className="font-body-strong text-system-primary">Directory</TableHead>
                                    <TableHead className="font-body-strong text-system-primary">Last Updated</TableHead>
                                </>
                                :
                                <>
                                    <TableHead className="font-body-strong text-system-primary">Uploaded on</TableHead>
                                    <TableHead className="font-body-strong text-system-primary">File size</TableHead>
                                    {!areAllDocumentsReady && (
                                        <TableHead className="font-body-strong text-system-primary">Status</TableHead>
                                    )}
                                </>
                            }

                            {/* <TableHead className="font-body-strong text-system-primary w-8"></TableHead> */}
                        </TableRow>
                    </TableHeader>

                    <TableBody>
                        {documents.map((d) => (
                            <TableRow key={d.document_id} className="">
                                {askSelection && (
                                    <TableCell className="font-body text-system-body">
                                        <Checkbox
                                            checked={selected?.includes(d.document_name)}
                                            disabled={false}
                                            onCheckedChange={() => onSelect?.(d.document_name)}
                                        />
                                    </TableCell>
                                )}
                                <TableCell className="font-body text-system-body hover:underline hover:cursor-pointer !max-w-[640px] !overflow-hidden" onClick={() => {
                                    const link = getFileLinkV2(d);
                                    if (!link) {
                                        alert("We failed to get the file link, please try again shortly");
                                    };
                                    window.open(link, '_blank');
                                }}>
                                    <div className="flex gap-2 items-center">
                                        {getFileIcon(d.document_type_friendly, 'w-8 h-8')}
                                        {askSelection ?
                                            <TypographyBody className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[232px]">
                                                {d.document_name}
                                            </TypographyBody>
                                            :
                                            <TypographyBody className="line-clamp-2">
                                                {d.document_name}
                                            </TypographyBody>
                                        }
                                    </div>
                                </TableCell>

                                {askSelection ?
                                    <>
                                        <TableCell className="font-body text-system-body">{getDirectoryFromDocument(d, friendlyOrgName(userContext.user?.app_metadata.organization_id))}</TableCell>
                                        <TableCell className="font-body text-system-body">{getCreatedAt(d)}</TableCell>
                                    </>
                                    :
                                    <>
                                        <TableCell className="font-body text-system-body">{getCreatedAt(d)}</TableCell>
                                        <TableCell className="font-body text-system-body">{formatBytes(d.document_size_bytes || 0)}</TableCell>
                                        {!areAllDocumentsReady && (
                                            <TableCell className="font-body text-system-body">
                                                {!d.document_is_ready_to_use && getBadge(d.document_processing_status)}
                                            </TableCell>
                                        )}
                                    </>
                                }


                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}

            {loading && (
                <div>
                    <Skeleton className="h-10 m-2" />
                    <Skeleton className="h-10 m-2" />
                    <Skeleton className="h-10 m-2" />
                    <Skeleton className="h-10 m-2" />
                    <Skeleton className="h-10 m-2" />
                </div>
            )}

            {!loading && error && (
                <ErrorMessage message="We failed to list resources, please try again shortly" />
            )}
        </div>
    )
}
