import { Citation, SourceDocument } from "../../types/types";
import { Skeleton } from "@/components/ui/skeleton";
import { DotIcon } from "lucide-react";
import { embedCitationsV2 } from "../../utils/embedCitations";
import Markdown from "markdown-to-jsx";
import { ResponsiveContainer } from "./ResponsiveContainer";
import { SourceDocumentId } from "@/contexts/AssistantContext";
import { Sources } from "./Sources";
import { CustomTooltip } from "../CustomTooltip";
import { TypographyP, TypographyH4 } from "../ui/Typography";
import { ReactNode } from "react";
import { createPortal } from "react-dom";

type Props = {
    isLoading: boolean;
    text: string;
    isComplete: boolean;
    documents: SourceDocument[];
    citations: Citation[];
    compact?: boolean;
}

interface AnswerBlockProps {
    text: string;
    citations: Citation[];
    documents: SourceDocument[];
    isComplete: boolean;
    compact?: boolean;
}

interface CitedTextProps {
    text: string;
    citations: Citation[];
    documents: SourceDocument[];
    isComplete: boolean;
    compact?: boolean;
}

interface CursorProps {
    show: boolean;
}

function LoadingBlocks() {
    return (
        <ResponsiveContainer>
            <Skeleton className="w-4/6 h-[20px] my-2" />
            <Skeleton className="w-full h-[20px] my-2" />
            <Skeleton className="w-full h-[20px] my-2" />
            <Skeleton className="w-5/6 h-[20px] my-2" />
        </ResponsiveContainer>
    )
}

function CitedText({ text, citations, documents, isComplete, compact }: CitedTextProps) {
    const embeddedCitations = embedCitationsV2({ text, citations, highlightCitation: "", documents, isComplete });

    function Cite({ id, children, documentIds, highlight }: { id: string, children: string, documentIds: string, highlight: boolean, onSelectCitation: (s: SourceDocumentId[]) => void }) {
        try {
            const classes = highlight ? "" : "!font-medium hover:cursor-pointer text-black z-[0]";
            const containerClasses = highlight ? "" : "citation-background"
            const parsedDocumentIds = JSON.parse(documentIds || "[]");

            return (
                <span className={containerClasses}>
                    <span
                        className={`relative ${compact ? 'font-medium' : 'font-medium'} ${classes}`}
                        data-tooltip-id={`cited-text-${window.location.pathname}`}
                        data-tooltip-place="left"
                        data-parsed-document-ids={parsedDocumentIds}
                    >
                        {children}
                    </span>
                </span>
            )
        } catch (e) {
            console.error(`[desia-web-app] error rendering Cite with props: ${JSON.stringify({ id, documentIds })}`);
            return children;
        }
    }

    const StyledTable = ({ children }: { children: ReactNode }) =>
        <>
            <table className="space-x-3 text-left [&_th]:text-system-primary [&_td]:pr-3 mb-4">{children}</table>
        </>

    const StyledH4 = ({ children }: { children: ReactNode }) => <TypographyH4 className="text-system-primary mb-4">{children}</TypographyH4>

    return (
        <Markdown options={{
            overrides: {
                Cite: {
                    component: Cite
                },
                Cursor: {
                    component: Cursor
                },
                p: {
                    component: ({ children }: { children: ReactNode }) =>
                        <>
                            <TypographyP>{children}</TypographyP>
                            <br />
                        </>
                },
                h1: {
                    component: StyledH4
                },
                h2: {
                    component: StyledH4
                },
                h3: {
                    component: StyledH4
                },
                h4: {
                    component: StyledH4
                },
                ul: {
                    component: ({ children }: { children: ReactNode }) => <ul className="font-body [&>li]:mb-2 ml-10">{children}</ul>
                },
                ol: {
                    component: ({ children }: { children: ReactNode }) => <ol className="font-body [&>li]:mb-2 ml-10">{children}</ol>
                },
                table: {
                    component: StyledTable
                },
                strong: {
                    component: ({ children }: { children: ReactNode }) => <TypographyP className="!font-body-strong !text-system-primary">{children}</TypographyP>
                },
            },
            wrapper: 'span',
            forceBlock: true,
        }}
        >
            {embeddedCitations}
        </Markdown>
    )
}

function Cursor({ show }: CursorProps) {
    if (!show) return null;
    return <DotIcon className="w-6 h-6 text-system-body inline" />;
}

function AnswerBlock({ text, citations, documents, isComplete, compact }: AnswerBlockProps) {
    return (
        <ResponsiveContainer>
            <div className={`text-system-body leading-7 ${compact ? "font-label" : "font-body"}`}>
                <span className="">
                    <CitedText
                        text={text}
                        citations={citations}
                        documents={documents}
                        isComplete={isComplete}
                        compact={compact}
                    />
                </span>
            </div>
        </ResponsiveContainer>
    )
}

function getUniqueDocumentIds(inputIds: string[]) {
    try {
        const documentIds = inputIds.map(d => {
            if (d.startsWith("web-search")) {
                return d;
            }
            const [id] = d.split("_");
            return id;
        })
        const uniqueDocumentIds = Array.from(new Set(documentIds));
        return uniqueDocumentIds;
    } catch (e) {
        console.error(e);
        return [];
    }
}

export function FinalAnswer({ isLoading, isComplete, text, citations, documents, compact }: Props) {
    const CitationTooltip = () => {
        return (
            <CustomTooltip
                id={`cited-text-${window.location.pathname}`}
                clickable={true}
                openOnClick={true}
                onRender={({ activeAnchor }) => {
                    const citationIds = getUniqueDocumentIds((activeAnchor?.getAttribute('data-parsed-document-ids') || '').split(','))
                    const citationDocuments = documents.filter(d => {
                        try {
                            let id = "";
                            if (d.document_id.includes("python")) {
                                return false;
                            }

                            if (d.document_id.startsWith("web-search")) {
                                id = d.document_id;
                            } else {
                                id = d.document_id.split("_")[0];
                            }

                            return citationIds.includes(id);
                        } catch (e) {
                            console.error(e);
                            return false;
                        }
                    });

                    if (citationDocuments.length === 0) return null

                    return (
                        <div className="max-w-100vw sm:w-[360px]">
                            <Sources documents={citationDocuments} showTabs={false} />
                        </div>
                    )
                }}
            />
        )
    }

    if (isLoading) {
        return <LoadingBlocks />
    }
    return (
        <>
            {/* Avoid z index issues */}
            {createPortal(<CitationTooltip />, document.body)}

            <AnswerBlock
                text={text}
                citations={citations}
                documents={documents}
                isComplete={isComplete}
                compact={compact}
            />
        </>

    )
}
