import { SourceDocument } from "@/types/types"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"
import { getGlobalUniqueDocuments } from "@/utils/components";
import Source from "../Source";

interface IProps {
    documents: SourceDocument[];
    showTabs: boolean
}

// todo: consider unique documents as prop
// todo: support tabbing through links
export function Sources({ documents, showTabs }: IProps) {
    const uniqueDocuments = getGlobalUniqueDocuments(documents);
    const webpages = uniqueDocuments.filter(d => d.document_id.toLowerCase().includes("web"));
    const internalDocs = uniqueDocuments.filter(d => !d.document_id.toLowerCase().includes("web"));

    const hasWebpages = webpages.length > 0;
    const hasInternalDocs = internalDocs.length > 0;

    const defaultValue = hasWebpages ? "webpages" : "internal";

    return (
        <div className="flex flex-col gap-6">
            {showTabs ?
                <Tabs defaultValue={defaultValue} className="rounded-md">
                    <TabsList className="mb-6">
                        {hasWebpages && <TabsTrigger value="webpages">Webpages</TabsTrigger>}
                        {hasInternalDocs && <TabsTrigger value="internal">Internal Documents</TabsTrigger>}
                    </TabsList>

                    {hasWebpages && (
                        <TabsContent value="webpages" className="flex flex-col gap-4 overflow-y-auto max-h-[calc(100vh-480px)]">
                            {webpages.map(d =>
                                <Source
                                    key={d.document_id}
                                    id={d.document_id}
                                    url={d.url}
                                    title={d.title}
                                    text={d.text}
                                    showBorder={true}
                                    metadata={d.doc_metadata}
                                />
                            )}
                        </TabsContent>
                    )}

                    {hasInternalDocs && (
                        <TabsContent value="internal" className="flex flex-col gap-4">
                            {internalDocs.map(d =>
                                <Source
                                    key={d.document_id}
                                    id={d.document_id}
                                    url={d.url}
                                    title={d.title}
                                    text={d.text}
                                    showBorder={true}
                                    metadata={d.doc_metadata}
                                />
                            )}
                        </TabsContent>
                    )}
                </Tabs>
                :
                <div className="flex flex-col gap-4">
                    {uniqueDocuments.map(d =>
                        <Source
                            key={d.document_id}
                            id={d.document_id}
                            url={d.url}
                            title={d.title}
                            text={d.text}
                            showBorder={true}
                            metadata={d.doc_metadata}
                        />
                    )}
                </div>
            }
        </div>
    )
}
