import { useEffect, useState, useRef, useContext } from "react";
import { SelectedCitations, SourceDocumentId, getStreamStatus } from "../../contexts/AssistantContext";
import { AskTextbox } from "../AskTextbox";
import { UserChatMessage, SystemChatMessage, getMessageId } from "../Chat";
import { ErrorMessage } from "../ErrorMessage";
import { useLocation } from "react-router-dom";
import { AssistantAskMode, ChatMessage, QueryState, SourceConnector } from "../../types/types";
import { checkUserFlag } from "../../utils/utils";
import { ChatBox } from "./ChatBox";
import { LayoutContext } from "@/contexts/LayoutContext";
import { UserContext } from "@/contexts/UserContext";
import Divider from "../ui/divider";

interface IProps {
    handleAsk: (args: { message: string, mode: AssistantAskMode }) => void;
    selectCitation: (messageId: string, documentId: SourceDocumentId[]) => void;
    showAllCitations: (messageId: string) => void;
    conversation: ChatMessage[];
    queryState: QueryState;
    selectedCitations: SelectedCitations;
    sources: SourceConnector[]
}

export function Conversation({
    handleAsk,
    selectCitation,
    showAllCitations,
    conversation,
    queryState,
    selectedCitations,
    sources,
}: IProps) {
    const { settings } = useContext(UserContext);

    const [hoverCitations, setHoverCitations] = useState<SourceDocumentId[]>([]);
    const [hoverDocument, setHoverDocument] = useState<SourceDocumentId>("");
    const [hasRendered, setHasRendered] = useState(false);
    const [isChatBoxCoveringText, setIsChatBoxCoveringText] = useState(false);

    const location = useLocation();
    const layoutContext = useContext(LayoutContext);
    const conversationBottomMarginRef = useRef<HTMLDivElement>(null);
    const conversationBottomRef = useRef<HTMLDivElement>(null);
    const lastMessage = conversation.length > 0 ? conversation[conversation.length - 1] : null;

    const streamStatus = getStreamStatus(lastMessage, sources);
    const askExpired = false; // fixme: checkAskExpired(lastMessage);
    const isBusy = (streamStatus !== 'Ready') && (!queryState.error) && !askExpired;

    useEffect(() => {
        if (conversation.length > 0 && !hasRendered) {
            conversationBottomRef.current?.scrollIntoView({ behavior: 'instant' })
            setHasRendered(true)
        }
    }, [conversation])

    useEffect(() => {
        setHoverCitations([]);
        setHoverDocument("");
        setHasRendered(false)
    }, [location.pathname])

    useEffect(() => {
        const handleScroll = () => {
            const scrolledTo = window.scrollY + window.innerHeight
            const clientWidth = document.body.clientWidth
            const emptyHeight = clientWidth > 568 ? clientWidth > 768 ? 160 : 80 : 40

            if (document.body.scrollHeight - emptyHeight < scrolledTo) {
                setIsChatBoxCoveringText(true)
            } else {
                setIsChatBoxCoveringText(false)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const hoverEffects = checkUserFlag('assistant: highlight citations');

    const isLegacyDesign = checkUserFlag("ui: legacy design system");
    const expertModeAvailable = true;

    const chatboxContainerStyle = `w-full fixed bottom-0 left-0 right-0 ${layoutContext.showSidebar ? 'md:ml-[220px] lg:ml-[320px] md:w-[calc(100%-220px)] lg:w-[calc(100%-320px)]' : ''} md:px-8`

    return (
        <div className={`${isLegacyDesign ? "chat-component-container" : "max-w-[720px] mx-auto"}`}>
            <div className={`${isLegacyDesign ? "chat-messages" : ""}`}>
                {conversation
                    .map((chatMessage, idx) => {
                        if (chatMessage.role === 'user') {
                            return <UserChatMessage key={`user__${idx}`} message={chatMessage} />
                        }
                        if (chatMessage.role == 'system') {
                            const messageId = getMessageId(chatMessage, idx);

                            return (
                                <>
                                    <SystemChatMessage
                                        key={`system_${idx}`}
                                        messageId={messageId}
                                        message={chatMessage}
                                        selectedCitations={selectedCitations[messageId] || []}
                                        onSelectCitation={selectCitation}
                                        onShowAllCitations={showAllCitations}
                                        hoveredCitations={hoverCitations}
                                        onHoverCitation={(documentIds: SourceDocumentId[]) => {
                                            setHoverCitations(documentIds);
                                        }}
                                        onHoverOffCitation={() => {
                                            setHoverCitations([]);
                                        }}
                                        hoveredDocument={hoverDocument}
                                        onHoverDocument={(d: SourceDocumentId) => {
                                            if (hoverEffects) {
                                                setHoverDocument(d);
                                            }
                                        }}
                                        onFollowUpQuestionClick={(q) => {
                                            conversationBottomMarginRef.current?.scrollIntoView({ behavior: 'smooth' })
                                            handleAsk({ message: q, mode: settings.assistant.mode })
                                        }}
                                        showFollowUpQuestions={idx === conversation.length - 1}
                                    />
                                    {idx !== conversation.length - 1 && (
                                        <div className="mt-6 mb-8">
                                            <Divider />
                                        </div>
                                    )}
                                </>
                            )
                        }
                        return <></>
                    })}

                {queryState.error && (
                    <ErrorMessage message={`We failed to stream the response: ${queryState.error}. Try asking again`} />
                )}

                {askExpired && (
                    <ErrorMessage message="missing response. If you refreshed the page, wait a minute and refresh. Otherwise, continue to ask further questions." />
                )}
            </div>
            <div className={`h-[1px] w-full bg-transparent`} ref={conversationBottomRef}></div>

            {isBusy && (
                <>
                    <div style={{ height: `${window.innerHeight / 2}px` }} className="bg-transparent"></div>
                    <div className={`h-[1px] w-full bg-transparent`} ref={conversationBottomMarginRef}></div>
                </>
            )}

            {!isLegacyDesign && (
                <div className={chatboxContainerStyle}>
                    <ChatBox
                        expertModeAvailable={expertModeAvailable}
                        initialMessage=""
                        handleSubmit={(v) => {
                            conversationBottomMarginRef.current?.scrollIntoView({ behavior: 'smooth' })
                            setTimeout(() => {
                                handleAsk(v)
                            }, 100)
                        }}
                        status={streamStatus}
                        isConversation={true}
                        showBorder={!isChatBoxCoveringText}
                    />
                </div>
            )}

            {isLegacyDesign && (
                <div className="chat-asktextbox-container">
                    <AskTextbox
                        placeholder="Ask Desia"
                        canSubmit={!isBusy}
                        handleAsk={(q: string) => {
                            handleAsk({ message: q, mode: 'simple' });
                        }}
                        status={streamStatus}
                    />
                </div>
            )}
        </div>
    )
}
