import * as React from "react"

import { cn } from "@/shadcn/utils";
import { SearchIcon, X } from "lucide-react";
import { TypographyBody } from "./Typography";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  isSearch?: boolean
  isCloseVisible?: boolean
  onCloseClick?: () => void
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, label, isSearch, isCloseVisible, onCloseClick, ...props }, ref) => {
    const style = `flex h-10 w-full rounded-sm border border-system-border-regular bg-system-secondary ${isSearch ? 'px-[38px] py-2' : 'px-3 py-2'} font-body text-system-primary ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:system-placeholder focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50`
    return (
      <div className="flex flex-col gap-1">
        {label && (
          <TypographyBody isStrong={true}>
            {label}
          </TypographyBody>
        )}

        <div className="relative">
          {isSearch && (
            <SearchIcon className="absolute left-3 top-[11px] h-[18px] w-[18px] stroke-[2px] stroke-system-placeholder" />
          )}

          <input
            type={type}
            className={cn(
              style,
              className
            )}
            ref={ref}
            {...props}
          />

          {(isSearch && isCloseVisible) && (
            <div className="cursor-pointer" onClick={() => onCloseClick?.()}>
              <X className="absolute right-3 top-2 h-[24px] w-[24px] stroke-[1.5px] stroke-system-primary" />
            </div>
          )}
        </div>
      </div>
    )
  }
)
Input.displayName = "Input"

export { Input }